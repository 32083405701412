import Component from '@glimmer/component';
import { action } from '@ember/object';

import FormField from '@mysolomon/solomon-ui-addon/src/utils/form-field';

export default class FormsBookingSettingsComponent extends Component {
  fieldSet = {
    adminEmails: new FormField({
      key: 'adminEmails',
      value: this.args.model.adminEmails,
    }),
  };

  @action
  addEmail(model, item) {
    model.adminEmails = [...model.adminEmails, item];
  }

  @action
  removeEmail(model, item) {
    model.adminEmails = [...model.adminEmails.filter((i) => i !== item)];
  }
}
