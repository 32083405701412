import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { restartableTask, timeout } from 'ember-concurrency';

import { searchByIndex } from '../../utilities/algolia-helpers';

import type { SearchAlgoliaCustomerResponse } from '../../../types/oneday-academy-ui/index';
import type Store from '@ember-data/store';

interface AlgoliaCustomerLookupComponentSignature {
  Args: {
    onChange: (selected: unknown) => void;
  };
}

export default class AlgoliaCustomerLookupComponent extends Component<AlgoliaCustomerLookupComponentSignature> {
  @service declare store: Store;

  searchTask = restartableTask(async (queryString) => {
    await timeout(800);

    const data = await searchByIndex<SearchAlgoliaCustomerResponse>({
      indexName: 'uno_customers',
      query: queryString,
      hitsPerPage: 25,
      page: 0,
    });

    return data?.['0']?.hits;
  });

  @action
  async setSelectedCustomer(selected: SearchAlgoliaCustomerResponse | null) {
    let customerModel = null;

    if (selected) {
      customerModel = await this.store.findRecord('customer', selected.objectID);
    }

    if (this.args.onChange) {
      this.args.onChange(customerModel);
    }
  }
}
