export function initialize(appInstance) {
  // appInstance.inject('route', 'foo', 'service:foo');
  const intercom = appInstance.lookup('service:intercom');

  intercom.boot();
}

export default {
  initialize,
};
