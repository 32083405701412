import Model, { attr } from '@ember-data/model';

export default class BookingDetailsModel extends Model {
  @attr('string') declare presenterNotes?: string;

  @attr('string') declare timetable?: string;

  @attr('string') declare yearGroups?: string;

  @attr('string') declare additionalNeeds?: string;

  @attr('string') declare topicKnowledge?: string;

  @attr('string') declare facilities?: string;

  @attr('string') declare technology?: string;

  @attr('string') declare carPark?: string;

  @attr('boolean', { defaultValue: false }) declare marketingConsent: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'booking-details': BookingDetailsModel;
  }
}
