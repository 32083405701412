import Transform from '@ember-data/serializer/transform';

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    array: ArrayTransform;
  }
}

export default class ArrayTransform extends Transform {
  deserialize(serialized: []) {
    return serialized;
  }

  serialize(deserialized: []) {
    return deserialized;
  }
}
