import Transform from '@ember-data/serializer/transform';

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'boolean-default-true': BooleanDefaultTrueTransform;
  }
}
// This custom transform is required because firebase returns `null` for boolean fields that are not set
// By default, ember-data will always set the value to `false` if it's `null`
// It will only respect the default value if the field is `undefined`
// This transform will allow us to set the default value to `true` for boolean fields
// It is a copy of the transfrom from: https://github.com/emberjs/data/blob/v5.3.0/packages/serializer/src/-private/transforms/boolean.js
export default class BooleanDefaultTrueTransform extends Transform {
  deserialize(serialized: boolean | string | number | null | undefined, options: { allowNull: boolean }) {
    if ((serialized === null || serialized === undefined) && options.allowNull === true) {
      return null;
    }

    let type = typeof serialized;

    if (type === 'boolean') {
      return serialized;
    } else if (type === 'string') {
      return /^(true|t|1)$/i.test(serialized as string);
    } else if (type === 'number') {
      return serialized === 1;
    } else {
      return true;
    }
  }

  serialize(deserialized: boolean, options: { allowNull: boolean }) {
    if ((deserialized === null || deserialized === undefined) && options.allowNull === true) {
      return null;
    }

    return Boolean(deserialized);
  }
}
