import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type PresenterModel from './presenter';

export default class PresenterAvailabilityModel extends Model {
  @attr('timestamp') declare date: Date;

  @attr('string') declare note: string;

  @attr('enum', { values: ['yes', 'no', 'maybe'] }) declare status: 'yes' | 'no' | 'maybe' | null;

  @belongsTo('presenter', { async: true, inverse: 'availability' }) declare presenter: AsyncBelongsTo<PresenterModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'presenter-availability': PresenterAvailabilityModel;
  }
}
