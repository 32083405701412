import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { getApp } from 'ember-cloud-firestore-adapter/firebase/app';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import ENV from 'oneday-academy-ui/config/environment';

export default class ApplicationRoute extends Route {
  @service analytics;

  @service router;

  @service session;

  @service intercom;

  constructor(owner, args) {
    super(owner, args);

    const { router } = this;

    router.on('routeDidChange', () => {
      this.analytics.logScreenView(router.currentRouteName, router.currentURL);
    });

    // connect to firebase emulators
    if (ENV.firebaseEmulatorSettings) {
      if (ENV.firebaseEmulatorSettings.functionsEmulator) {
        const functions = getFunctions(getApp(), 'europe-west2');

        connectFunctionsEmulator(
          functions,
          ENV.firebaseEmulatorSettings.functionsEmulator.host,
          ENV.firebaseEmulatorSettings.functionsEmulator.port
        );
      }
    }

    this.session.get('session').on('invalidationSucceeded', () => {
      this.intercom.shutdown();
    });
  }

  redirect(model, transition) {
    const emailVerified = this.session.get('data.authenticated.claims.email_verified');

    if (!transition.targetName === 'auth' && this.session.isAuthenticated && !emailVerified) {
      this.router.transitionTo('verify');
    }
  }
}
