export const videoTypes: VideoTypes[] = [
  'challenge', // Challenge
  'coolDown', // Cool Down
  'danceWorkshop', // Dance Workshop
  'dramaActivity', // Drama Activity
  'dramaWorkshop', // Drama Workshop
  'skillsWorkshop', // Skills Workshop
  'song', // Song
  'teacherDevelopment', // Teacher CPD
  'fullDayWorkshop', // Workshop - Full Day
  'warmUp', // Warm Up
  'wellbeingWorkshop', // Well-Being Workshop
  'parentVideo', // Parent Video
];

export type VideoTypes =
  | 'challenge'
  | 'coolDown'
  | 'danceWorkshop'
  | 'dramaActivity'
  | 'dramaWorkshop'
  | 'skillsWorkshop'
  | 'song'
  | 'teacherDevelopment'
  | 'fullDayWorkshop'
  | 'warmUp'
  | 'wellbeingWorkshop'
  | 'parentVideo';

export const salesChannels: SalesChannels[] = [
  'callToOffice',
  'emailCampaign',
  'eventOrMeeting',
  'feedbackFollowUp',
  'hello@onedaycreative.com',
  'liveChat',
  'planMySchoolTrip',
  'previousCustomer',
  'rebookingEmail',
  'referral',
  'socialMedia',
  'updatesEmail',
  'websiteEnquiry',
  'workshopsForSchools',
];

export type SalesChannels =
  | 'callToOffice'
  | 'emailCampaign'
  | 'eventOrMeeting'
  | 'feedbackFollowUp'
  | 'hello@onedaycreative.com'
  | 'liveChat'
  | 'planMySchoolTrip'
  | 'previousCustomer'
  | 'rebookingEmail'
  | 'referral'
  | 'socialMedia'
  | 'updatesEmail'
  | 'websiteEnquiry'
  | 'workshopsForSchools';

export const regions: Regions[] = [
  'eastMidlands',
  'lincsAndEastCoast',
  'london',
  'northEast',
  'northWest',
  'scotland',
  'south',
  'southEast',
  'southWestAndWales',
  'westMidlands',
  'yorkshire',
  'international',
];

export type Regions =
  | 'eastMidlands'
  | 'lincsAndEastCoast'
  | 'london'
  | 'northEast'
  | 'northWest'
  | 'scotland'
  | 'south'
  | 'southEast'
  | 'southWestAndWales'
  | 'westMidlands'
  | 'yorkshire'
  | 'international';

export const authorities: Authorities[] = [
  'aberdeen',
  'aberdeenshire',
  'angus',
  'argyllAndBute',
  'barking',
  'barnet',
  'barnsley',
  'bathAndNeSomerset',
  'bedford',
  'bexley',
  'birmingham',
  'blackburn',
  'blackpool',
  'blaenauGwent',
  'bolton',
  'bournemouth',
  'bracknellForest',
  'bradford',
  'brent',
  'bridgend',
  'brightonAndHove',
  'bristol',
  'bromley',
  'buckinghamshire',
  'bury',
  'caerphilly',
  'calderdale',
  'cambridge',
  'camden',
  'cardiff',
  'centralBedfordshire',
  'cheshireEast',
  'cheshireWestAndChester',
  'cityOfLondon',
  'clackmannanshire',
  'conway',
  'cornwall',
  'coventry',
  'croydon',
  'cumbria',
  'darlington',
  'derby',
  'derbyshire',
  'devon',
  'doncaster',
  'dorset',
  'dudley',
  'dumfriesAndGalloway',
  'dundee',
  'durham',
  'ealing',
  'eastAyrshire',
  'eastDunbartonshire',
  'eastLothian',
  'eastRenrewshire',
  'eastRiding',
  'eastSussex',
  'edinburgh',
  'enfield',
  'essex',
  'falkirk',
  'fife',
  'gateshead',
  'glasgow',
  'gloucestershire',
  'greenwich',
  'hackney',
  'halton',
  'hammersmith',
  'hampshire',
  'haringey',
  'harrow',
  'hartlepool',
  'havering',
  'herefordshire',
  'hertfordshire',
  'hillingdon',
  'hounslow',
  'hull',
  'inverclyde',
  'isleOfWight',
  'islington',
  'kent',
  'kingston',
  'kirklees',
  'knowsley',
  'lambeth',
  'lancashire',
  'leeds',
  'leicester',
  'leicestershire',
  'lewisham',
  'lincolnshire',
  'liverpool',
  'luton',
  'manchester',
  'medway',
  'merthyrTydfil',
  'merton',
  'middlesborough',
  'midlothian',
  'miltonKeynes',
  'monmouthshire',
  'newcastleUponTyne',
  'newham',
  'newport',
  'norfolk',
  'northAyrshire',
  'northEastLincs',
  'northLanarkshire',
  'northLincs',
  'northSomerset',
  'northTyneside',
  'northYorkshire',
  'northamptonshire',
  'northumberland',
  'nottsAndNottingham',
  'oldham',
  'oxfordshire',
  'perthAndKinross',
  'peterborough',
  'plymouth',
  'poole',
  'portsmouth',
  'powys',
  'rbkc',
  'reading',
  'redbridge',
  'redcarAndCleveland',
  'renfrewshire',
  'rhonddaCynonTaf',
  'richmond',
  'rochdale',
  'rotherham',
  'rutland',
  'salford',
  'sandwell',
  'scottishBorders',
  'sefton',
  'sheffield',
  'shropshire',
  'slough',
  'solihull',
  'somerset',
  'southAyrshire',
  'southGloucestershire',
  'southLanarkshire',
  'southTyneside',
  'southampton',
  'southend',
  'southwark',
  'stHelens',
  'staffordshire',
  'stockport',
  'stocktonOnTees',
  'stokeOnTrent',
  'suffolk',
  'sunderland',
  'surrey',
  'sutton',
  'swansea',
  'swindon',
  'tameside',
  'telford',
  'thurrock',
  'torbay',
  'torfaen',
  'towerHamlets',
  'trafford',
  'wakefield',
  'wales',
  'walsall',
  'waltham',
  'wandsworth',
  'warrington',
  'warwickshire',
  'westBerkshire',
  'westDunbartonshire',
  'westLothian',
  'westSussex',
  'westminster',
  'wigan',
  'wiltshire',
  'windsorAndMaidenhead',
  'wirral',
  'wokingham',
  'wolverhampton',
  'worcestershire',
  'wrexham',
  'valeOfGlamorgan',
  'york',
];

export type Authorities =
  | 'aberdeen'
  | 'aberdeenshire'
  | 'angus'
  | 'argyllAndBute'
  | 'barking'
  | 'barnet'
  | 'barnsley'
  | 'bathAndNeSomerset'
  | 'bedford'
  | 'bexley'
  | 'birmingham'
  | 'blackburn'
  | 'blackpool'
  | 'blaenauGwent'
  | 'bolton'
  | 'bournemouth'
  | 'bracknellForest'
  | 'bradford'
  | 'brent'
  | 'bridgend'
  | 'brightonAndHove'
  | 'bristol'
  | 'bromley'
  | 'buckinghamshire'
  | 'bury'
  | 'caerphilly'
  | 'calderdale'
  | 'cambridge'
  | 'camden'
  | 'cardiff'
  | 'centralBedfordshire'
  | 'cheshireEast'
  | 'cheshireWestAndChester'
  | 'cityOfLondon'
  | 'clackmannanshire'
  | 'conway'
  | 'cornwall'
  | 'coventry'
  | 'croydon'
  | 'cumbria'
  | 'darlington'
  | 'derby'
  | 'derbyshire'
  | 'devon'
  | 'doncaster'
  | 'dorset'
  | 'dudley'
  | 'dumfriesAndGalloway'
  | 'dundee'
  | 'durham'
  | 'ealing'
  | 'eastAyrshire'
  | 'eastDunbartonshire'
  | 'eastLothian'
  | 'eastRenrewshire'
  | 'eastRiding'
  | 'eastSussex'
  | 'edinburgh'
  | 'enfield'
  | 'essex'
  | 'falkirk'
  | 'fife'
  | 'gateshead'
  | 'glasgow'
  | 'gloucestershire'
  | 'greenwich'
  | 'hackney'
  | 'halton'
  | 'hammersmith'
  | 'hampshire'
  | 'haringey'
  | 'harrow'
  | 'hartlepool'
  | 'havering'
  | 'herefordshire'
  | 'hertfordshire'
  | 'hillingdon'
  | 'hounslow'
  | 'hull'
  | 'inverclyde'
  | 'isleOfWight'
  | 'islington'
  | 'kent'
  | 'kingston'
  | 'kirklees'
  | 'knowsley'
  | 'lambeth'
  | 'lancashire'
  | 'leeds'
  | 'leicester'
  | 'leicestershire'
  | 'lewisham'
  | 'lincolnshire'
  | 'liverpool'
  | 'luton'
  | 'manchester'
  | 'medway'
  | 'merthyrTydfil'
  | 'merton'
  | 'middlesborough'
  | 'midlothian'
  | 'miltonKeynes'
  | 'newcastleUponTyne'
  | 'monmouthshire'
  | 'newham'
  | 'newport'
  | 'norfolk'
  | 'northAyrshire'
  | 'northEastLincs'
  | 'northLanarkshire'
  | 'northLincs'
  | 'northSomerset'
  | 'northTyneside'
  | 'northYorkshire'
  | 'northamptonshire'
  | 'northumberland'
  | 'nottsAndNottingham'
  | 'oldham'
  | 'oxfordshire'
  | 'perthAndKinross'
  | 'peterborough'
  | 'plymouth'
  | 'poole'
  | 'portsmouth'
  | 'powys'
  | 'rbkc'
  | 'reading'
  | 'redbridge'
  | 'redcarAndCleveland'
  | 'renfrewshire'
  | 'rhonddaCynonTaf'
  | 'richmond'
  | 'rochdale'
  | 'rotherham'
  | 'rutland'
  | 'salford'
  | 'sandwell'
  | 'scottishBorders'
  | 'sefton'
  | 'sheffield'
  | 'shropshire'
  | 'slough'
  | 'solihull'
  | 'somerset'
  | 'southAyrshire'
  | 'southGloucestershire'
  | 'southLanarkshire'
  | 'southTyneside'
  | 'southampton'
  | 'southend'
  | 'southwark'
  | 'stHelens'
  | 'staffordshire'
  | 'stockport'
  | 'stocktonOnTees'
  | 'stokeOnTrent'
  | 'suffolk'
  | 'sunderland'
  | 'surrey'
  | 'sutton'
  | 'swindon'
  | 'swansea'
  | 'tameside'
  | 'telford'
  | 'thurrock'
  | 'torbay'
  | 'torfaen'
  | 'towerHamlets'
  | 'trafford'
  | 'wakefield'
  | 'wales'
  | 'walsall'
  | 'waltham'
  | 'wandsworth'
  | 'warrington'
  | 'warwickshire'
  | 'westBerkshire'
  | 'westDunbartonshire'
  | 'westLothian'
  | 'westSussex'
  | 'westminster'
  | 'wigan'
  | 'wiltshire'
  | 'windsorAndMaidenhead'
  | 'wirral'
  | 'wokingham'
  | 'wolverhampton'
  | 'worcestershire'
  | 'wrexham'
  | 'valeOfGlamorgan'
  | 'york';
