import Component from '@glimmer/component';

import { getDownloadURL, getMetadata, getStorage, ref } from 'ember-cloud-firestore-adapter/firebase/storage';
import { trackedFunction } from 'ember-resources/util/function';

export default class FirebaseFileComponent extends Component {
  get name() {
    return !this.metadata.isLoading ? this.metadata.value?.customMetadata?.originalName : null;
  }

  downloadUrl = trackedFunction(this, async () => {
    if (!this.args.filePath) {
      return;
    }

    const storage = getStorage();
    const imageRef = ref(storage, this.args.filePath);
    const url = await getDownloadURL(imageRef);

    return url;
  });

  metadata = trackedFunction(this, async () => {
    if (!this.args.filePath) {
      return;
    }

    const storage = getStorage();
    const imageRef = ref(storage, this.args.filePath);
    const metadata = await getMetadata(imageRef);

    return metadata;
  });
}
