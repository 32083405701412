import Component from '@glimmer/component';
import { action } from '@ember/object';

import FormField from '@mysolomon/solomon-ui-addon/src/utils/form-field';

export default class FormsBookingDetailsComponent extends Component {
  fieldSet = {
    presenterNotes: new FormField({
      key: 'presenterNotes',
      value: this.args.model.presenterNotes,
    }),
    timetable: new FormField({
      key: 'timetable',
      value: this.args.model.timetable,
    }),
    yearGroups: new FormField({
      key: 'yearGroups',
      value: this.args.model.yearGroups,
    }),
    additionalNeeds: new FormField({
      key: 'additionalNeeds',
      value: this.args.model.additionalNeeds,
    }),
    topicKnowledge: new FormField({
      key: 'topicKnowledge',
      value: this.args.model.topicKnowledge,
    }),
    facilities: new FormField({
      key: 'facilities',
      value: this.args.model.facilities,
    }),
    technology: new FormField({
      key: 'technology',
      value: this.args.model.technology,
    }),
    carPark: new FormField({
      key: 'carPark',
      value: this.args.model.carPark,
    }),
    marketingConsent: new FormField({
      key: 'pricemarketingConsent',
      value: this.args.model.marketingConsent,
    }),
  };

  @action
  setProperty(target, property, value) {
    target[property] = value;
    this.fieldSet[property].value = value;
  }
}
