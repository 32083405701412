import Service, { inject as service } from '@ember/service';

import { getAuth } from 'ember-cloud-firestore-adapter/firebase/auth';
import { findRecord } from 'ember-data-resources';
import { DateTime } from 'luxon';

import type SessionService from '../../types/ember-simple-auth/services/session';
import type UserModel from '../models/user';
import type FirebaseHelperService from './firebase-helper';
import type Store from '@ember-data/store';

export default class CurrentUserService extends Service {
  @service declare session: SessionService;

  @service declare store: Store;

  @service declare firebaseHelper: FirebaseHelperService;

  get auth() {
    return getAuth();
  }

  user = findRecord<UserModel>(this, 'user', () => [
    this.userId,
    {
      adapterOptions: {
        isRealtime: true,
      },
    },
  ]);

  get userId() {
    return this.auth.currentUser?.uid;
  }

  get trialEndsFromNow() {
    if (this.user?.record) {
      return DateTime.fromJSDate(this.user.record.trialEnd).toRelative();
    }

    return;
  }

  get isBasic() {
    if (this.user?.record) {
      return (this.user.record.trialEnded && this.user.record.subscription !== 'premium') || false;
    }

    return;
  }

  get isTrialing() {
    return (
      (this.user.record && this.user.record.trialEnded !== true && this.user.record.subscription !== 'premium') || false
    );
  }

  get isPremium() {
    return (this.user.record && this.user.record.subscription === 'premium') || false;
  }
}
