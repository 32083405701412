import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { Changeset } from 'ember-changeset';

export default class HeaderComponent extends Component {
  @service session;

  @service router;

  @service intercom;

  @service video;

  @service booking;

  @service currentUser;

  @tracked showVideoAdd = false;

  @tracked showBookingAdd = false;

  @tracked videoToAdd = null;

  @tracked showProfileMenu = false;

  @tracked showMobileMenu = false;

  @tracked showQuickAdd = false;

  @tracked bookingToAdd = null;

  @tracked showInfoMenu = false;

  @action
  logout() {
    this.session.invalidate();
  }

  @action
  async addVideo() {
    this.videoToAdd = this.video.createVideo();
    this.showQuickAdd = false;
    this.showVideoAdd = true;
  }

  @action
  async saveVideo(changesetObj, successAction) {
    this.video.saveVideo(changesetObj, successAction);
  }

  @action
  onVideoSaved() {
    this.videoToAdd = null;
    this.showVideoAdd = false;
  }

  @action
  async addBooking() {
    this.bookingToAdd = Changeset(await this.booking.createBooking());
    this.showQuickAdd = false;
    this.showBookingAdd = true;
  }

  @action
  async saveBooking(bookingChangeset, detailsChangeset, feedbackChangeset, settingsChangeset, successAction) {
    this.booking.saveBooking(bookingChangeset, successAction);
  }

  @action
  onBookingClosed(bookingChangeset) {
    if (bookingChangeset && bookingChangeset.get('isDirty')) {
      bookingChangeset.rollback();
    }

    this.bookingToAdd = null;
    this.showBookingAdd = false;
  }

  @action
  openMenu(menu) {
    this[menu] = true;
  }

  @action
  closeMenu(menu) {
    this[menu] = false;
  }

  @action
  toggleMenu(menu) {
    if (this[menu]) {
      this.closeMenu(menu);
    } else {
      this.openMenu(menu);
    }
  }

  clickInsideMenuTrigger(buttonId, event) {
    const buttonElement = document.getElementById(buttonId);

    // The `buttonElement` could not exist if the element has been removed from the DOM
    return buttonElement ? buttonElement.contains(event.target) : false;
  }

  @action
  allowClickOutsideFocusTrap(buttonId, event) {
    return this.clickInsideMenuTrigger(buttonId, event);
  }

  @action
  clickOutsideFocusTrapDeactivates(buttonId, event) {
    return !this.clickInsideMenuTrigger(buttonId, event);
  }
}
