import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class BookingEditComponent extends Component {
  @service store;

  @tracked formIsInvalid = false;

  @tracked showForm = false;

  @tracked currentTab = 0;

  get isNewBooking() {
    return this.args.booking.get('isNew');
  }

  @action
  onOpen() {
    this.showForm = true;
  }

  @action
  onClose(booking, details, feedback, settings) {
    if (this.args.onClose) {
      this.args.onClose(booking, details, feedback, settings);
    }

    this.showForm = false;
  }

  @action
  setTab(tab) {
    if (tab.preventDefault) {
      tab.preventDefault();
    }

    if (tab.target) {
      this.currentTab = +tab.target.value;
    } else {
      this.currentTab = tab;
    }
  }
}
