import { getApp } from 'ember-cloud-firestore-adapter/firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import type { SearchResponse } from '@algolia/client-search';

export interface SearchAlgoliaParams {
  query: string;
  filters?: string | undefined;
  indexName: string;
  hitsPerPage: number;
  page: number;
}

export async function searchByIndex<T>(params: SearchAlgoliaParams): Promise<SearchResponse<T>[] | undefined> {
  const { indexName, filters, query, hitsPerPage, page } = params;
  const app = getApp();
  const functions = getFunctions(app, 'europe-west2');
  const algoliaSearch = httpsCallable<SearchAlgoliaParams, SearchResponse<T>[]>(functions, 'api-http-searchAlgolia');
  const { data } = await algoliaSearch({ indexName, query, ...(filters ? { filters } : {}), hitsPerPage, page });

  return data;
}
