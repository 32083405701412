import Transform from '@ember-data/serializer/transform';

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    enum: EnumTransform;
  }
}

export default class EnumTransform extends Transform {
  deserialize(serialized: string): string {
    return serialized;
  }

  serialize(deserialized: string): string {
    return deserialized;
  }
}
