import Component from '@glimmer/component';
import { action } from '@ember/object';

import { getDownloadURL, getMetadata, getStorage, ref } from 'ember-cloud-firestore-adapter/firebase/storage';
import { trackedFunction } from 'ember-resources/util/function';
export default class FirebaseImageComponent extends Component {
  get name() {
    return !this.metadata.isLoading ? this.metadata.value?.customMetadata?.originalName : null;
  }

  downloadUrl = trackedFunction(this, async () => {
    if (!this.args.filePath) {
      return;
    }

    const storage = getStorage();
    const imageRef = ref(storage, this.args.filePath);
    // TODO: work out why storage emulator isn't working in exec mode and remove try/catch
    // const url = await getDownloadURL(imageRef);
    let url;

    try {
      url = await getDownloadURL(imageRef);
    } catch (err) {
      console.error(err);
    }

    return url;
  });

  metadata = trackedFunction(this, async () => {
    if (!this.args.filePath) {
      return;
    }

    const storage = getStorage();
    const imageRef = ref(storage, this.args.filePath);
    // TODO: work out why storage emulator isn't working in exec mode and remove try/catch
    // const metadata = await getMetadata(imageRef);
    let metadata;

    try {
      metadata = await getMetadata(imageRef);
    } catch (err) {
      console.error(err);
    }

    return metadata;
  });

  @action
  onImgRender(element) {
    if ('IntersectionObserver' in window) {
      this.setupLazyLoadWithIntersectionApi(element);
    } else {
      this.setupLazyLoadFallback(element);
    }
  }

  setupLazyLoadWithIntersectionApi(lazyloadImage) {
    const imageObserver = new IntersectionObserver((entries /* , observer */) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target;

          image.src = this.downloadUrl.value;
          image.classList.remove('lazy');
          imageObserver.unobserve(image);
        }
      });
    });

    imageObserver.observe(lazyloadImage);
  }

  setupLazyLoadFallback(img) {
    let lazyloadThrottleTimeout;

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(() => {
        const scrollTop = window.pageYOffset;

        // lazyloadImages.forEach((img) => {
        if (img.offsetTop < window.innerHeight + scrollTop) {
          img.src = img.dataset.src;
          img.classList.remove('lazy');
          // }
          // });
          // if (lazyloadImages.length === 0) {
          document.removeEventListener('scroll', lazyload);
          window.removeEventListener('resize', lazyload);
          window.removeEventListener('orientationChange', lazyload);
        }
      }, 20);
    }

    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }
}
