import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedRoute extends Route {
  @service session;

  @service currentUser;

  @service router;

  @service intercom;

  @service applicationState;

  async activate() {
    if (!this.intercom.currentUser) {
      try {
        const authUser = this.session.get('data.authenticated.authUser');

        if (!this.currentUser.user.record) {
          await this.currentUser.user.retry();
        }

        const userModel = this.currentUser.user.record;
        let intercomUser;

        if (authUser && userModel) {
          intercomUser = {
            id: userModel.intercomId,
            user_id: authUser.uid,
            email: authUser.email,
            name: authUser.displayName || authUser.email,
            organisation: userModel.organisation || null,
            job_title: userModel.jobTitle,
          };
          this.intercom.update(intercomUser);
        }
        // console.log(`intercom updated at @authenticated for user ${authUser.email}`);
      } catch (err) {
        // console.log(err);
      }
    }
  }

  redirect(model, transition) {
    const { isAuthenticated } = this.session;

    if (!isAuthenticated) {
      if (transition.targetName !== 'authenticated.index') {
        this.applicationState.previousTransition = transition;
        this.router.transitionTo('login');
      } else {
        this.router.transitionTo('schools');
      }
    } else if (transition.targetName === 'authenticated.index') {
      this.router.transitionTo('authenticated.video');
    }
  }
}
