import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import FormField from '@mysolomon/solomon-ui-addon/src/utils/form-field';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'ember-cloud-firestore-adapter/firebase/storage';
import { v4 as uuid } from 'uuid';

export default class FileUploadComponent extends Component {
  @service firebaseHelper;

  @tracked selectedFile;

  @tracked uploadedPath;

  @tracked uploading = false;

  @tracked progress = 0;

  @tracked filename = '';

  fieldSet = {
    filename: new FormField({
      key: 'filename',
      value: this.filename,
      validators: ['required'],
    }),
  };

  @action
  setProperty(target, property, value) {
    target[property] = value;
    this.fieldSet[property].value = value;
  }

  @action
  onFileSelect(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    // eslint-disable-next-line prefer-destructuring
    this.selectedFile = evt.target.files[0];
  }

  @action
  uploadFile(evt) {
    this.uploading = true;

    const file = this.selectedFile;
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `${this.args.storagePath ? this.args.storagePath + '/' : 'files/'}` + `${uuid()}-${file.name}`
    );

    evt.stopPropagation();
    evt.preventDefault();

    const metadata = {
      contentType: file.type,
      customMetadata: {
        originalName: !isEmpty(this.filename) ? this.filename : file.name,
      },
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + this.progress + '% done');
        // switch (snapshot.state) {
        //   case 'paused':
        //     console.log('Upload is paused');
        //     break;
        //   case 'running':
        //     console.log('Upload is running');
        //     break;
        // }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((/*downloadURL*/) => {
          // console.log('File available at', downloadURL);
          this.uploadedPath = uploadTask.snapshot.metadata.fullPath;
          // TODO: implement progress bar
          this.uploading = false;
          this.selectedFile = null;
          this.filename = '';

          if (this.args.onChange) {
            this.args.onChange(this.uploadedPath);
          }
        });
      }
    );
  }
}
