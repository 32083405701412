import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import { restartableTask, timeout } from 'ember-concurrency';

import { searchByIndex } from './algolia-helpers';

import type { SearchAlgoliaParams } from './algolia-helpers';
import type { SearchResponse } from '@algolia/client-search';

export default class AlgoliaSearch<T> {
  @tracked searchResults: SearchResponse<T> | undefined;

  @tracked searchResultIds: string[] = [];

  @tracked indexName: string;

  @tracked query: string;

  @tracked filters: string | undefined;

  @tracked hitsPerPage: number;

  @tracked page: number;

  @tracked nbHits = 0;

  @tracked nbPages = 0;

  constructor(args: SearchAlgoliaParams) {
    const { indexName, query, filters, hitsPerPage, page } = args;

    this.searchResults = undefined;
    this.indexName = indexName;
    this.query = query;
    this.filters = filters;
    this.hitsPerPage = hitsPerPage;
    this.page = page;
  }

  search = restartableTask(async () => {
    await timeout(500);

    const data = await searchByIndex<T>({
      query: this.query,
      filters: this.filters,
      indexName: this.indexName,
      hitsPerPage: this.hitsPerPage,
      page: this.page,
    });

    const results = data?.[0] ? data[0] : undefined;

    if (results) {
      this.searchResults = results;
      this.searchResultIds = results.hits.map((hit) => hit.objectID);
      this.page = results.page;
      this.nbHits = results.nbHits;
      this.nbPages = results.nbPages;
    }

    return { results, ids: this.searchResultIds };
  });

  @action
  decrementPage() {
    this.page -= 1;
    this.search.perform();
  }

  @action
  incrementPage() {
    this.page += 1;
    this.search.perform();
  }
}
