import Model, { type AsyncHasMany, attr, hasMany } from '@ember-data/model';

import { authorities, regions } from 'oneday-academy-ui/data/constants';

import type BookingModel from './booking';
import type UserModel from './user';

interface UnoCustomerCapsuleAccount {
  email: string;
  id: number;
  name: string;
}

export default class CustomerModel extends Model {
  @attr('string') declare name: string;

  @attr('string') declare location: string;

  @attr('string') declare accessCode: string;

  @attr('string') declare referralCode: string;

  @attr('string') declare adminEmail: string;

  @attr('number') declare referralCredit: number;

  @attr('number') declare referralCount: number;

  @attr('string', { defaultValue: 'basic' }) declare subscription: string;

  @attr('string') declare paymentMethod: string;

  @attr({
    defaultValue() {
      return {};
    },
  })
  declare stripeAccount: Record<string, unknown>;

  @attr({
    defaultValue() {
      return {};
    },
  })
  declare capsuleAccount: UnoCustomerCapsuleAccount;

  @attr('timestamp') declare createdAt: Date;

  @attr('array', { defaultValue: () => [] }) declare userIds: string[]; // denormalized

  @attr('array', { defaultValue: () => [] }) declare billingAdminIds: string[]; // denormalized

  @attr('enum', {
    enumOptions: regions,
    defaultValue: '',
  })
  declare region: (typeof regions)[number];

  @attr('enum', {
    enumOptions: authorities,
    defaultValue: '',
  })
  declare authority: (typeof authorities)[number];

  @hasMany('user', {
    async: true,
    inverse: 'customer',
    isRealtime: true,
  })
  declare users: AsyncHasMany<UserModel>;

  @hasMany('booking', {
    async: true,
    inverse: 'customer',
    isRealtime: true,
  })
  declare bookings: AsyncHasMany<BookingModel>;

  get heading1() {
    return `${this.name} - ${this.location || 'No Location'}`;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    customer: CustomerModel;
  }
}
