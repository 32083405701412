import Model, { type AsyncBelongsTo, type AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';

import { regions } from 'oneday-academy-ui/data/constants';

import type PresenterAvailabilityModel from './presenter-availability';
import type UserModel from './user';
import type VideoModel from './video';

export default class PresenterModel extends Model {
  @attr('string') declare name: string;

  @attr('timestamp') declare availabilityUpdatedAt: Date;

  @attr('enum', {
    enumOptions: regions,
    defaultValue: '',
  })
  declare region: (typeof regions)[number];

  @hasMany('presenter-availability', {
    async: true,
    inverse: 'presenter',
    isRealtime: true,
  })
  declare availability: AsyncHasMany<PresenterAvailabilityModel>;

  @hasMany('video', {
    async: true,
    inverse: 'presenters',
    isRealtime: true,
  })
  declare videos: AsyncHasMany<VideoModel>;

  @belongsTo('user', {
    async: true,
    inverse: 'presenter',
    isRealtime: true,
  })
  declare user: AsyncBelongsTo<UserModel>;

  @attr('boolean', { defaultValue: true }) declare isActive: boolean;

  get heading1() {
    return this.name;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    presenter: PresenterModel;
  }
}
