import Model, { attr } from '@ember-data/model';

export default class BookingFeedbackModel extends Model {
  @attr('number') declare contentScore?: number | null;

  @attr('number') declare presenterScore?: number | null;

  @attr('boolean-default-true', {
    defaultValue: true,
  })
  declare enjoyment: boolean | null;

  @attr('boolean-default-true', { defaultValue: true }) declare learning: boolean | null;

  @attr('string') declare likes?: string;

  @attr('string') declare otherComments?: string;

  @attr('boolean', { defaultValue: false }) declare rebooking: boolean | null;

  @attr('array', { defaultValue: () => [] }) declare interests: [];

  @attr('boolean', { defaultValue: false }) declare focusWeekConsent: boolean | null;

  @attr('boolean', { defaultValue: false }) declare sharingConsent: boolean | null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'booking-feedback': BookingFeedbackModel;
  }
}
