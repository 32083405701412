import Component from '@glimmer/component';
import { service } from '@ember/service';

export default class AdminModeComponent extends Component {
  @service session;

  get admin() {
    return this.session.get('data.authenticated.claims.admin');
  }

  get subscription() {
    return this.session.get('data.authenticated.claims.subscription');
  }

  get presenter() {
    return !!this.session.get('data.authenticated.claims.presenterId');
  }
}
