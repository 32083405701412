
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


d("oneday-academy-ui/adapters/-json-api", function(){ return i("../adapters/-json-api.js");});
d("oneday-academy-ui/adapters/application", function(){ return i("../adapters/application.js");});
d("oneday-academy-ui/adapters/cloud-firestore-modular", function(){ return i("../adapters/cloud-firestore-modular.js");});
d("oneday-academy-ui/app", function(){ return i("../app.js");});
d("oneday-academy-ui/authenticators/firebase", function(){ return i("../authenticators/firebase.js");});
d("oneday-academy-ui/authenticators/oneday-firebase", function(){ return i("../authenticators/oneday-firebase.ts");});
d("oneday-academy-ui/component-managers/glimmer", function(){ return i("../component-managers/glimmer.js");});
d("oneday-academy-ui/config/environment", function(){ return i("../config/environment.js");});
d("oneday-academy-ui/container-debug-adapter", function(){ return i("../container-debug-adapter.js");});
d("oneday-academy-ui/data-adapter", function(){ return i("../data-adapter.js");});
d("oneday-academy-ui/data/constants", function(){ return i("../data/constants.ts");});
d("oneday-academy-ui/data/dataurls", function(){ return i("../data/dataurls.ts");});
d("oneday-academy-ui/initializers/app-version", function(){ return i("../initializers/app-version.js");});
d("oneday-academy-ui/initializers/ember-data-data-adapter", function(){ return i("../initializers/ember-data-data-adapter.js");});
d("oneday-academy-ui/initializers/ember-data", function(){ return i("../initializers/ember-data.js");});
d("oneday-academy-ui/initializers/ember-simple-auth", function(){ return i("../initializers/ember-simple-auth.js");});
d("oneday-academy-ui/instance-initializers/boot-intercom", function(){ return i("../instance-initializers/boot-intercom.js");});
d("oneday-academy-ui/instance-initializers/ember-data", function(){ return i("../instance-initializers/ember-data.js");});
d("oneday-academy-ui/instance-initializers/ember-router-scroll", function(){ return i("../instance-initializers/ember-router-scroll.js");});
d("oneday-academy-ui/instance-initializers/ember-simple-auth", function(){ return i("../instance-initializers/ember-simple-auth.js");});
d("oneday-academy-ui/instance-initializers/firebase-settings", function(){ return i("../instance-initializers/firebase-settings.js");});
d("oneday-academy-ui/models/booking-details", function(){ return i("../models/booking-details.ts");});
d("oneday-academy-ui/models/booking-feedback", function(){ return i("../models/booking-feedback.ts");});
d("oneday-academy-ui/models/booking-itinerary", function(){ return i("../models/booking-itinerary.ts");});
d("oneday-academy-ui/models/booking-settings", function(){ return i("../models/booking-settings.ts");});
d("oneday-academy-ui/models/booking", function(){ return i("../models/booking.ts");});
d("oneday-academy-ui/models/customer", function(){ return i("../models/customer.ts");});
d("oneday-academy-ui/models/presenter-availability", function(){ return i("../models/presenter-availability.ts");});
d("oneday-academy-ui/models/presenter", function(){ return i("../models/presenter.ts");});
d("oneday-academy-ui/models/review", function(){ return i("../models/review.ts");});
d("oneday-academy-ui/models/stage", function(){ return i("../models/stage.ts");});
d("oneday-academy-ui/models/subject", function(){ return i("../models/subject.ts");});
d("oneday-academy-ui/models/user", function(){ return i("../models/user.ts");});
d("oneday-academy-ui/models/video", function(){ return i("../models/video.ts");});
d("oneday-academy-ui/models/workshop", function(){ return i("../models/workshop.ts");});
d("oneday-academy-ui/router", function(){ return i("../router.js");});
d("oneday-academy-ui/serializers/-default", function(){ return i("../serializers/-default.js");});
d("oneday-academy-ui/serializers/-json-api", function(){ return i("../serializers/-json-api.js");});
d("oneday-academy-ui/serializers/-rest", function(){ return i("../serializers/-rest.js");});
d("oneday-academy-ui/serializers/application", function(){ return i("../serializers/application.js");});
d("oneday-academy-ui/serializers/cloud-firestore-modular", function(){ return i("../serializers/cloud-firestore-modular.js");});
d("oneday-academy-ui/services/-ensure-registered", function(){ return i("../services/-ensure-registered.js");});
d("oneday-academy-ui/services/-firestore-data-manager", function(){ return i("../services/-firestore-data-manager.js");});
d("oneday-academy-ui/services/analytics", function(){ return i("../services/analytics.js");});
d("oneday-academy-ui/services/application-state", function(){ return i("../services/application-state.js");});
d("oneday-academy-ui/services/booking", function(){ return i("../services/booking.ts");});
d("oneday-academy-ui/services/cookies", function(){ return i("../services/cookies.js");});
d("oneday-academy-ui/services/current-user", function(){ return i("../services/current-user.ts");});
d("oneday-academy-ui/services/dialog-stack-provider", function(){ return i("../services/dialog-stack-provider.js");});
d("oneday-academy-ui/services/firebase-helper", function(){ return i("../services/firebase-helper.ts");});
d("oneday-academy-ui/services/intercom", function(){ return i("../services/intercom.js");});
d("oneday-academy-ui/services/keyboard", function(){ return i("../services/keyboard.js");});
d("oneday-academy-ui/services/page-title-list", function(){ return i("../services/page-title-list.js");});
d("oneday-academy-ui/services/page-title", function(){ return i("../services/page-title.js");});
d("oneday-academy-ui/services/router-scroll", function(){ return i("../services/router-scroll.js");});
d("oneday-academy-ui/services/session", function(){ return i("../services/session.js");});
d("oneday-academy-ui/services/store", function(){ return i("../services/store.js");});
d("oneday-academy-ui/services/video", function(){ return i("../services/video.js");});
d("oneday-academy-ui/session-stores/application", function(){ return i("../session-stores/application.js");});
d("oneday-academy-ui/session-stores/firebase", function(){ return i("../session-stores/firebase.js");});
d("oneday-academy-ui/src/utils/field-validators", function(){ return i("../src/utils/field-validators.js");});
d("oneday-academy-ui/src/utils/form-field", function(){ return i("../src/utils/form-field.js");});
d("oneday-academy-ui/transforms/array", function(){ return i("../transforms/array.ts");});
d("oneday-academy-ui/transforms/boolean-default-true", function(){ return i("../transforms/boolean-default-true.ts");});
d("oneday-academy-ui/transforms/boolean", function(){ return i("../transforms/boolean.js");});
d("oneday-academy-ui/transforms/date", function(){ return i("../transforms/date.js");});
d("oneday-academy-ui/transforms/enum", function(){ return i("../transforms/enum.ts");});
d("oneday-academy-ui/transforms/number", function(){ return i("../transforms/number.js");});
d("oneday-academy-ui/transforms/string", function(){ return i("../transforms/string.js");});
d("oneday-academy-ui/transforms/timestamp", function(){ return i("../transforms/timestamp.js");});
d("oneday-academy-ui/utilities/algolia-helpers", function(){ return i("../utilities/algolia-helpers.ts");});
d("oneday-academy-ui/utilities/algolia-search", function(){ return i("../utilities/algolia-search.ts");});
d("oneday-academy-ui/utils/custom-errors", function(){ return i("../utils/custom-errors.js");});
d("oneday-academy-ui/utils/titleize", function(){ return i("../utils/titleize.js");});
d("oneday-academy-ui/templates/application", function(){ return i("../templates/application.hbs");});
d("oneday-academy-ui/controllers/application", function(){ return i("../controllers/application.js");});
d("oneday-academy-ui/routes/application", function(){ return i("../routes/application.js");});
d("oneday-academy-ui/templates/authenticated", function(){ return i("../templates/authenticated.hbs");});
d("oneday-academy-ui/routes/authenticated", function(){ return i("../routes/authenticated.js");});
d("oneday-academy-ui/routes/index", function(){ return i("../routes/index.js");});
d("oneday-academy-ui/templates/error", function(){ return i("../templates/error.hbs");});
d("oneday-academy-ui/templates/loading", function(){ return i("../templates/loading.hbs");});



w._embroiderRouteBundles_ = [
  {
    names: ["auth"],
    load: function() {
      return import("./_route_/auth.js");
    }
  },
  {
    names: ["authenticated.admin.availability"],
    load: function() {
      return import("./_route_/authenticated.admin.availability.js");
    }
  },
  {
    names: ["authenticated.admin.bookings","authenticated.admin.bookings.edit"],
    load: function() {
      return import("./_route_/authenticated.admin.bookings.js");
    }
  },
  {
    names: ["authenticated.admin.customers"],
    load: function() {
      return import("./_route_/authenticated.admin.customers.js");
    }
  },
  {
    names: ["authenticated.admin.presenters"],
    load: function() {
      return import("./_route_/authenticated.admin.presenters.js");
    }
  },
  {
    names: ["authenticated.admin.users"],
    load: function() {
      return import("./_route_/authenticated.admin.users.js");
    }
  },
  {
    names: ["authenticated.admin.workshops"],
    load: function() {
      return import("./_route_/authenticated.admin.workshops.js");
    }
  },
  {
    names: ["authenticated.admin"],
    load: function() {
      return import("./_route_/authenticated.admin.js");
    }
  },
  {
    names: ["authenticated.availability"],
    load: function() {
      return import("./_route_/authenticated.availability.js");
    }
  },
  {
    names: ["authenticated.calendar","authenticated.calendar.index","authenticated.calendar.itineraries","authenticated.calendar.itineraries.view"],
    load: function() {
      return import("./_route_/authenticated.calendar.js");
    }
  },
  {
    names: ["authenticated.user","authenticated.user.bookings","authenticated.user.bookings.feedback","authenticated.user.bookings.manage","authenticated.user.profile"],
    load: function() {
      return import("./_route_/authenticated.user.js");
    }
  },
  {
    names: ["authenticated.video","authenticated.video.index","authenticated.video.view","authenticated.video.view.feedback"],
    load: function() {
      return import("./_route_/authenticated.video.js");
    }
  },
  {
    names: ["booking.confirmation"],
    load: function() {
      return import("./_route_/booking.confirmation.js");
    }
  },
  {
    names: ["booking.manage"],
    load: function() {
      return import("./_route_/booking.manage.js");
    }
  },
  {
    names: ["login"],
    load: function() {
      return import("./_route_/login.js");
    }
  },
  {
    names: ["order.cancel"],
    load: function() {
      return import("./_route_/order.cancel.js");
    }
  },
  {
    names: ["order.success"],
    load: function() {
      return import("./_route_/order.success.js");
    }
  },
  {
    names: ["signup"],
    load: function() {
      return import("./_route_/signup.js");
    }
  },
  {
    names: ["verify"],
    load: function() {
      return import("./_route_/verify.js");
    }
  },
  {
    names: ["faqs"],
    load: function() {
      return import("./_route_/faqs.js");
    }
  },
  {
    names: ["not-found"],
    load: function() {
      return import("./_route_/not-found.js");
    }
  },
  {
    names: ["pricing"],
    load: function() {
      return import("./_route_/pricing.js");
    }
  },
  {
    names: ["schools"],
    load: function() {
      return import("./_route_/schools.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"name":"oneday-academy-ui","version":"0.0.0+f0ae9ca5"});
}

