import Component from '@glimmer/component';
import { action } from '@ember/object';

import FormField from '@mysolomon/solomon-ui-addon/src/utils/form-field';

export default class FormsBookingFeedbackComponent extends Component {
  fieldSet = {
    contentScore: new FormField({
      key: 'contentScore',
      value: this.args.model.contentScore,
      validators: ['required'],
    }),
    presenterScore: new FormField({
      key: 'presenterScore',
      value: this.args.model.presenterScore,
      validators: ['required'],
    }),
    enjoyment: new FormField({
      key: 'enjoyment',
      value: this.args.model.enjoyment,
    }),
    learning: new FormField({
      key: 'learning',
      value: this.args.model.learning,
    }),
    likes: new FormField({
      key: 'likes',
      value: this.args.model.likes,
      validators: ['required'],
    }),
    otherComments: new FormField({
      key: 'otherComments',
      value: this.args.model.otherComments,
    }),
    rebooking: new FormField({
      key: 'rebooking',
      value: this.args.model.rebooking,
    }),
    interests: new FormField({
      key: 'interests',
      value: this.args.model.interests,
    }),
    focusWeekConsent: new FormField({
      key: 'focusWeekConsent',
      value: this.args.model.focusWeekConsent,
    }),
    sharingConsent: new FormField({
      key: 'sharingConsent',
      value: this.args.model.sharingConsent,
    }),
  };

  @action
  setProperty(target, property, value) {
    target[property] = value;
    this.fieldSet[property].value = value;
  }

  @action
  setBool(target, property, value) {
    let boolValue = value === 'true';

    target[property] = boolValue;
    this.fieldSet[property].value = boolValue;
  }
}
