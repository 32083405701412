import Model, { type AsyncBelongsTo, type AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';

import { DateTime } from 'luxon';
import { customAlphabet } from 'nanoid';
import { salesChannels } from 'oneday-academy-ui/data/constants';

import type CustomerModel from './customer';
import type PresenterModel from './presenter';
import type UserModel from './user';
import type VideoModel from './video';
import type WorkshopModel from './workshop';

export default class BookingModel extends Model {
  @attr('string', { defaultValue: () => customAlphabet('1234567890ABCDEUVWXYZ', 8)() }) declare reference: string;

  @attr('timestamp') declare createdAt: Date;

  @attr('timestamp') declare deliveryDate: Date;

  @attr('string') declare contactName?: string;

  @attr('string') declare contactEmail?: string;

  @attr('number') declare price?: number;

  @attr('string') declare memo?: string;

  @attr('boolean', { defaultValue: false }) declare isConfirmed: boolean;

  @attr('boolean', { defaultValue: false }) declare hasDetails: boolean;

  @attr('boolean', { defaultValue: false }) declare hasFeedback: boolean;

  @attr('boolean', { defaultValue: false }) declare isCancelled: boolean;

  @attr('boolean', { defaultValue: false }) declare shouldNotifyPresenter: boolean;

  @attr('array', { defaultValue: () => [] }) declare workshopIds: string[]; // denormalised

  @attr('array', { defaultValue: () => [] }) declare workshopTitles: string[]; // denormalised

  @attr('array', { defaultValue: () => [] }) declare workshopThumbnails: string[]; // denormalised

  @attr('array', { defaultValue: () => [] }) declare adminEmails: string[]; // denormalised

  @attr('number') declare capsuleOpportunityId?: number | null;

  @attr('enum', {
    enumOptions: salesChannels,
    defaultValue: '',
  })
  declare salesChannel?: string;

  @hasMany('workshop', { async: true, inverse: 'bookings' }) declare workshops?: AsyncHasMany<WorkshopModel>;

  @hasMany('video', { async: true, inverse: null }) declare videos?: AsyncHasMany<VideoModel>;

  @belongsTo('presenter', { async: true, inverse: null }) declare presenter?: AsyncBelongsTo<PresenterModel>;

  @belongsTo('customer', {
    async: true,
    inverse: 'bookings',
  })
  declare customer?: AsyncBelongsTo<CustomerModel>;

  @belongsTo('user', { async: true, inverse: null }) declare owner?: AsyncBelongsTo<UserModel>;

  get heading1() {
    return `${this.reference}: ${DateTime.fromJSDate(this.deliveryDate).toFormat('dd-MM-yyyy')} at ${this.customer?.get(
      'heading1'
    )}`;
  }

  get searchIndex() {
    return `${this.reference} ${DateTime.fromJSDate(this.deliveryDate).toFormat('dd-MM-yyyy')} ${this.customer?.get(
      'heading1'
    )} ${(this.workshops || []).map((w) => w.title).join(' ')}`;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    booking: BookingModel;
  }
}
