import templateOnlyComponent from '@ember/component/template-only';

interface LoadingIconComponentSignature {
  Element: SVGSVGElement;
}

const LoadingIconComponent = templateOnlyComponent<LoadingIconComponentSignature>();

export default LoadingIconComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LoadingIcon: typeof LoadingIconComponent;
  }
}
