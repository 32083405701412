import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class VideoEditComponent extends Component {
  @tracked formIsInvalid = false;

  @tracked showForm = false;

  @action
  onOpen() {
    this.showForm = true;
  }

  @action
  onClose(changesetObj) {
    if (this.args.onClose) {
      this.args.onClose(changesetObj);
      this.showForm = false;
    }
  }
}
