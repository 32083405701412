import Model, { type AsyncHasMany, attr, hasMany } from '@ember-data/model';

import type BookingModel from './booking';

export default class WorkshopModel extends Model {
  @attr('string') declare title: string;

  @attr('string') declare thumbnail: string;

  @attr('boolean', { defaultValue: true }) declare isActive: boolean;

  @attr('array', { defaultValue: () => [] }) declare files: string[];

  @hasMany('booking', { async: true, inverse: 'workshops' }) declare bookings: AsyncHasMany<BookingModel>;

  @attr('boolean', { defaultValue: false }) declare hasProps: boolean;

  @attr('number', { defaultValue: () => 0 }) declare propsBudget: number;

  @attr('array', { defaultValue: () => [] }) declare propsList: string[];

  get heading1() {
    return this.title;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    workshop: WorkshopModel;
  }
}
