import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import FormField from '@mysolomon/solomon-ui-addon/src/utils/form-field';
import { query, where } from 'ember-cloud-firestore-adapter/firebase/firestore';
import { query as queryResource } from 'ember-data-resources';
import { sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
export default class FormsBookingComponent extends Component {
  @service store;

  @tracked selectedCustomer;

  constructor() {
    super(...arguments);

    this.selectedCustomer = this.args.model.customer;
  }

  presenter = queryResource(this, 'presenter', () => ({
    filter(reference) {
      return query(reference, where('isActive', '==', true));
    },
  }));

  fieldSet = {
    customer: new FormField({
      key: 'customer',
      value: this.args.model.customer,
      validators: ['required'],
    }),
    contactName: new FormField({
      key: 'contactName',
      value: this.args.model.contactName,
      validators: ['required'],
    }),
    contactEmail: new FormField({
      key: 'contactEmail',
      value: this.args.model.contactEmail,
      validators: ['required', 'email'],
    }),
    deliveryDate: new FormField({
      key: 'deliveryDate',
      value: this.args.model.deliveryDate,
      validators: ['required'],
    }),
    workshops: new FormField({
      key: 'workshops',
      value: this.args.model.workshops,
    }),
    presenter: new FormField({
      key: 'presenter',
      value: this.args.model.presenter,
    }),
    salesChannel: new FormField({
      key: 'salesChannel',
      value: this.args.model.salesChannel,
      validators: ['required'],
    }),
    price: new FormField({
      key: 'price',
      value: this.args.model.price,
      validators: ['required', 'number'],
    }),
    memo: new FormField({
      key: 'memo',
      value: this.args.model.memo,
    }),
    isConfirmed: new FormField({
      key: 'isConfirmed',
      value: this.args.model.isConfirmed,
    }),
    hasDetails: new FormField({
      key: 'hasDetails',
      value: this.args.model.hasDetails,
    }),
    hasFeedback: new FormField({
      key: 'hasFeedback',
      value: this.args.model.hasFeedback,
    }),
    isCancelled: new FormField({
      key: 'isCancelled',
      value: this.args.model.isCancelled,
    }),
    shouldNotifyPresenter: new FormField({
      key: 'shouldNotifyPresenter',
      value: this.args.model.shouldNotifyPresenter,
    }),
    videos: new FormField({
      key: 'videos',
      value: this.args.model.videos,
    }),
  };

  get salesChannelOptions() {
    const options = this.args?.model?.data?.constructor?.attributes?.get('salesChannel')?.options?.enumOptions;

    return sortBy(options);
  }

  get confirmationEmail() {
    if (!this.args.model.get('isNew') && this.args.model.customer) {
      const reference = this.args.model.reference;
      const accessCode = this.args.model.customer.get('accessCode');
      const isPremium = this.args.model.customer.get('subscription') === 'premium' ? true : false;
      const customerName = this.args.model.customer.get('name');
      const link = `https://oneday.uno/booking/confirmation?bookingReference=${reference}&accessCode=${accessCode}&isPremium=${isPremium}&customerName=${encodeURI(
        customerName
      )}`;

      return `mailto:?subject=Please confirm your booking&body=${encodeURIComponent(link)}`;
    }

    return false;
  }

  get reminderEmail() {
    if (!this.args.model.get('isNew') && this.args.model.customer) {
      const bookingId = this.args.model.id;
      const accessCode = this.args.model.customer.get('accessCode');
      const isPremium = this.args.model.customer.get('subscription') === 'premium' ? true : false;
      const customerName = this.args.model.customer.get('name');
      const link = `https://oneday.uno/booking/${bookingId}?accessCode=${accessCode}&isPremium=${isPremium}&customerName=${encodeURI(
        customerName
      )}`;

      return `mailto:?subject=Booking details required&body=${encodeURIComponent(link)}`;
    }

    return false;
  }

  @action
  setProperty(target, property, value) {
    target[property] = value;
    this.fieldSet[property].value = value;
  }

  @action
  setDeliveryDate(date) {
    const formattedDate = DateTime.fromISO(date).plus({ hours: 8 }).toJSDate();

    this.args.model.deliveryDate = formattedDate;
    this.fieldSet.deliveryDate.value = formattedDate;
  }

  @action
  setWorkshops(selected) {
    const selectedIds = (selected || []).map((w) => w.id);

    // TODO: this should be handled by a function
    this.args.model.workshops = selected;
    this.args.model.workshopIds = selectedIds;
    this.fieldSet.workshops.value = selected;
  }

  @action
  setSelectedCustomer(selected) {
    this.selectedCustomer = selected;
    this.args.model.customer = selected;
    this.fieldSet.customer.value = selected;
  }
}
