import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';

export default class VideoService extends Service {
  @service store;

  @service firebaseHelper;

  createVideo() {
    return this.store.createRecord('video', {});
  }

  @action
  async saveVideo(changesetObj, successAction) {
    const relations = ['subjects', 'stages', 'presenters'];
    const original = changesetObj.get('data');
    const changes = changesetObj.get('changes');

    for (const relation of relations) {
      await this.saveRelation(relation, original, changes);
    }

    await changesetObj.save();

    if (successAction) {
      successAction();
    }
  }

  async saveRelation(relation, original, changes) {
    const selectedRelationChanges = changes.find(({ key }) => key === relation);

    if (selectedRelationChanges) {
      const selectedRelatedItems = selectedRelationChanges.value;

      await this.firebaseHelper.setRelation(original, relation, selectedRelatedItems);
    }
  }
}
