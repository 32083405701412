import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';

import { getApp } from 'ember-cloud-firestore-adapter/firebase/app';
import { getAnalytics, logEvent, setCurrentScreen, setUserId } from 'firebase/analytics';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default class AnalyticsService extends Service {
  constructor(owner, args) {
    super(owner, args);

    const config = getOwner(this).resolveRegistration('config:environment');

    this.appName = config.APP.name || 'Oneday';
    this.appVersion = config.APP.version || undefined;
  }

  @tracked privateAnalytics;

  @tracked appName;

  @tracked appVersion;

  @tracked privateUserId;

  @service session;

  get analytics() {
    // TODO: ask Nate is private analytics has any advantages now getAnalytics simply returns the app instance?
    if (!this.privateAnalytics) {
      try {
        // eslint-disable-next-line ember/no-side-effects
        this.privateAnalytics = getAnalytics();
      } catch (err) {
        // console.log(`cannot send analytics as app doesn't exist yet`);
        return null;
      }
    }

    return this.privateAnalytics;
  }

  @action
  logScreenView(screenName, url) {
    // TODO: what to do with missed events because firebase app isn't ready
    if (this.analytics) {
      setCurrentScreen(this.analytics, screenName || url, { global: true });
      this.trackEvent('screen_view', { screenName, url });
    }
  }

  get userId() {
    if (!this.privateUserId) {
      const userId = this.session.get('data.authenticated.authUser.uid');

      if (userId) {
        this.setUserId(userId);
      }
    }

    return this.privateUserId;
  }

  setUserId(userId) {
    // TODO: what to do with missed events because firebase app isn't ready
    this.privateUserId = userId;

    // TODO: TEST - ask Nate what this is doing?
    if (this.analytics) {
      setUserId(this.analytics, userId);
    }
  }

  get defaultParams() {
    return {
      appName: this.appName,
      appVersion: this.appVersion,
    };
  }

  @action
  trackEvent(eventName, params = {}) {
    // TODO: what to do with missed events because firebase app isn't ready
    if (this.analytics) {
      const eventParams = { ...this.defaultParams, ...params };

      logEvent(this.analytics, eventName, eventParams);
    }
  }

  @action
  async logError(errorMessage, errorObject = {}) {
    try {
      const uid = this.userId || 'unauthenticated-user';
      const app = getApp();
      const functions = getFunctions(app, 'europe-west2');
      const addLog = httpsCallable(functions, 'api-http-addLog');

      await addLog({ uid, message: errorMessage, params: errorObject });
    } catch (err) {
      console.error(err);
    }
  }
}
