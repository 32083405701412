import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';

import { collection } from 'ember-cloud-firestore-adapter/firebase/firestore';

import type CurrentUserService from './current-user';
import type FirebaseHelperService from './firebase-helper';
import type Model from '@ember-data/model';
import type Store from '@ember-data/store';
import type { BufferedChangeset } from 'ember-changeset/types';
import type { Firestore } from 'firebase/firestore';

export default class BookingService extends Service {
  @service declare store: Store;

  @service declare currentUser: CurrentUserService;

  @service declare firebaseHelper: FirebaseHelperService;

  createBooking() {
    return this.store.createRecord('booking', {
      createdAt: new Date(),
      createdBy: this.currentUser.user.record,
    });
  }

  async saveBookingDetails(changesetObj: BufferedChangeset, successAction: () => void) {
    const id = changesetObj.get('id');

    await changesetObj.save({
      adapterOptions: {
        isRealtime: true,
        buildReference(db: Firestore) {
          return collection(db, 'bookings', id, 'booking_details');
        },
      },
    });

    if (successAction) {
      successAction();
    }
  }

  async saveBookingFeedback(changesetObj: BufferedChangeset, successAction: () => void) {
    const id = changesetObj.get('id');

    await changesetObj.save({
      adapterOptions: {
        isRealtime: true,
        buildReference(db: Firestore) {
          return collection(db, 'bookings', id, 'booking_feedback');
        },
      },
    });

    if (successAction) {
      successAction();
    }
  }

  async saveBookingSettings(changesetObj: BufferedChangeset, successAction: () => void) {
    const id = changesetObj.get('id');

    await changesetObj.save({
      adapterOptions: {
        isRealtime: true,
        buildReference(db: Firestore) {
          return collection(db, 'bookings', id, 'booking_settings');
        },
      },
    });

    if (successAction) {
      successAction();
    }
  }

  @action
  async saveBooking(changesetObj: BufferedChangeset, successAction: () => void) {
    const manyManyRelations = ['workshops'];
    const manyNoneRelations = ['videos'];
    const original = changesetObj.get('data');

    // this hack allows us to save the changeset first - allowing function onCreate to work
    // ensures onCreate has the attributes, rather than being the byproduct if setRelation creating a record
    const originalManyManyRelations: { [key: string]: Model[] } = {};

    for (const relation of manyManyRelations) {
      const relationValue = original[relation];

      if (relationValue !== undefined) {
        originalManyManyRelations[relation] = relationValue.slice();
      }
    }

    const originalManyNoneRelations: { [key: string]: Model[] } = {};

    for (const relation of manyNoneRelations) {
      const relationValue = original[relation];

      if (relationValue !== undefined) {
        originalManyNoneRelations[relation] = relationValue.slice();
      }
    }

    const changes = changesetObj.get('changes');

    await changesetObj.save();

    for (const relation of manyManyRelations) {
      await this.saveRelation(relation, original, changes, originalManyManyRelations);
    }

    for (const relation of manyNoneRelations) {
      await this.saveRelation(relation, original, changes, originalManyNoneRelations, false);
    }

    if (successAction) {
      successAction();
    }
  }

  // @ts-expect-error - TODO: fix typings
  async saveRelation(relation, original, changes, originalRelations = {}, inverse = true) {
    //@ts-expect-error - TODO: fix typings
    const selectedRelationChanges = changes.find(({ key }) => key === relation);

    if (selectedRelationChanges) {
      const selectedRelatedItems = selectedRelationChanges.value;

      await this.firebaseHelper.setRelation(original, relation, selectedRelatedItems, originalRelations, inverse);
    }
  }
}
