import Service, { inject as service } from '@ember/service';

import { addDoc, collection, doc, getFirestore } from 'ember-cloud-firestore-adapter/firebase/firestore';
import { pluralize } from 'ember-inflector';
import { difference } from 'lodash-es';
import ENV from 'oneday-academy-ui/config/environment';

import type Model from '@ember-data/model';
import type SessionService from 'ember-simple-auth/services/session';
import type { DocumentData, DocumentReference, Firestore, WriteBatch } from 'firebase/firestore';

export default class FirebaseHelperService extends Service {
  @service declare session: SessionService;

  get db() {
    return getFirestore();
  }

  async createViewToken(userId: string, videoId: string) {
    try {
      const created = await addDoc(collection(this.db, 'viewTokens'), {
        video: doc(this.db, 'videos', videoId),
        user: doc(this.db, 'users', userId),
      });

      return created;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("token couldn't be generated");
      throw err;
    }
  }

  // TODO: implement this: https://www.typescriptlang.org/play?#code/PQ18ZXToFAgAQBECmBjANgQwE6sQFsB7AE1UwGc44BLQgB2NwBdEBZMigGkQG9EAC2yV22AHYBPXgEFKk8egASIsVMQBfRADNcxQogDkAAVSEARqlwBaUthbZgJcpkMBuGlhGVEAMWLEiKgAHiyo4qQ+nC78cIiIAObEABQAlLHx8ejE4pTEmKgAdJjECcmGgrSGqR7xGnD1cF6UPgBCeEGh4ZEcXJgZiMbCohKS5doB1XGI5F74OgGUAFyIcgrKqqMAPNEUAHwNNLM4884ArgVGZpY2dg7ALJIMqJTA+Am0lCy4tC9OfYYBiEmKwZqhtNgLmxaOIwrgIegCLtMAAlVAfL64SQDeITYgrfzEWqZcx4FbtXDE+qNWC0un06AIYCIAAqggI2jOihYtByiBhiAAjmcXjycjQROsdFz0GLxIg8oRUL4ZXKtgBpTphCI+TEwhK8Fla7o+NHZXCkDWyeSKFQjKQ7Pp7Z3JZwUFYs3j4HByyiVBgrdXpPjTApsfDmnoAXkQyWwAHdsLQ2G7MABtb32Xm5f0AXVShRYxBkuFw2DGNWmSFIgXjwjCADcrIh48nBIgI8xIoc4GHEKTcIgY+JUPHEBS0h44Irlars8kB7xDHjKNU3IgkIBQcmn+ln3Pni6M4mIzzXG+ZgF4NwClOzQGXf77AmRxsABrAgsdmsgDKiAYOEkCR6FypCGD4jzPIg2DoMKnzJtmLbMC+iC3syxgsJQ1i0Akx74HQjDMGwJjgX81xWLY9jYAA+pRqbuE+aEYVhOGoHhIKEWhTwkRYVjUcQ5gAFYYCwdFIAxmHYcwLH0GxRgcc8rykbg1F4GWkjuEAA
  async setRelation<T extends Model, K extends string & Exclude<keyof T, keyof Model>>(
    model: T,
    relationshipKey: K,
    newRelations: [],
    originalRelations?: Record<string, Model[]>,
    inverse = true
  ) {
    let previousItems: Model[] = [];

    const originalRelatedItems = originalRelations ? originalRelations[relationshipKey] || [] : [];

    if (originalRelatedItems.length > 0) {
      previousItems = originalRelatedItems;
    } else {
      // @ts-expect-error - need to work out how to type K so this returns a relationship proxy!
      previousItems = (await model[relationshipKey]).slice();
    }

    const toAdd = difference(newRelations, previousItems);
    const toRemove = difference(previousItems, newRelations);

    for (const adding of toAdd) {
      if (!inverse) {
        await this.addManyNoneRelation(model, relationshipKey, adding);
      } else {
        await this.addManyManyRelation(model, relationshipKey, adding);
      }
    }

    for (const removing of toRemove) {
      if (!inverse) {
        await this.removeManyNoneRelation(model, relationshipKey, removing);
      } else {
        await this.removeManyManyRelation(model, relationshipKey, removing);
      }
    }

    model.set(relationshipKey, newRelations);
  }

  async addManyManyRelation(model: Model, relationshipKey: string, relatedModel: Model) {
    const modelName = pluralize(model.constructor.toString().split(':')[1] as string);

    return model.save({
      adapterOptions: {
        include(batch: WriteBatch, db: Firestore) {
          // Batch write to the users/<user_id>/subjects sub-collection
          batch.set(doc(db, `${modelName}/${model.get('id')}/${relationshipKey}/${relatedModel.get('id')}`), {
            referenceTo: doc(db, `${relationshipKey}/${relatedModel.get('id')}`),
          });
          // Batch write to the subjects/<group_id>/members sub-collection
          batch.set(doc(db, `${relationshipKey}/${relatedModel.get('id')}/${modelName}/${model.get('id')}`), {
            referenceTo: doc(db, `${modelName}/${model.get('id')}`),
          });
        },
      },
    });
  }

  async addManyNoneRelation(model: Model, relationshipKey: string, relatedModel: Model) {
    const modelName = pluralize(model.constructor.toString().split(':')[1] as string);

    return model.save({
      adapterOptions: {
        include(batch: WriteBatch, db: Firestore) {
          // Batch write to the users/<user_id>/subjects sub-collection
          batch.set(doc(db, `${modelName}/${model.get('id')}/${relationshipKey}/${relatedModel.get('id')}`), {
            referenceTo: doc(db, `${relationshipKey}/${relatedModel.get('id')}`),
          });
        },
      },
    });
  }

  async removeManyManyRelation(model: Model, relationshipKey: string, relatedModel: Model) {
    const modelName = pluralize(model.constructor.toString().split(':')[1] as string);

    return model.save({
      adapterOptions: {
        include(batch: WriteBatch, db: Firestore) {
          // Batch delete to the users/<user_id>/subjects sub-collection
          batch.delete(doc(db, `${modelName}/${model.get('id')}/${relationshipKey}/${relatedModel.get('id')}`));
          // Batch delete to the subjects/<group_id>/members sub-collection
          batch.delete(doc(db, `${relationshipKey}/${relatedModel.get('id')}/${modelName}/${model.get('id')}`));
        },
      },
    });
  }

  async removeManyNoneRelation(model: Model, relationshipKey: string, relatedModel: Model) {
    const modelName = pluralize(model.constructor.toString().split(':')[1] as string);

    return model.save({
      adapterOptions: {
        include(batch: WriteBatch, db: Firestore) {
          // Batch delete to the users/<user_id>/subjects sub-collection
          batch.delete(doc(db, `${modelName}/${model.get('id')}/${relationshipKey}/${relatedModel.get('id')}`));
        },
      },
    });
  }

  async attachCheckoutSession(
    price: number,
    customerName: string,
    referredByCode: string
  ): Promise<DocumentReference<DocumentData> | undefined> {
    const currentUser = this.session.data?.authenticated.authUser;
    let docRef;

    if (currentUser) {
      docRef = await addDoc(collection(this.db, 'customers_stripe', currentUser.uid, 'checkout_sessions'), {
        line_items: [
          {
            price,
            quantity: 1,
            dynamic_tax_rates: ['txr_1IO45wGhmuGAYCWVX15yVSFP'],
          },
        ],
        allow_promotion_codes: true,
        success_url: ENV.stripe.success_url,
        cancel_url: ENV.stripe.cancel_url,
        client_reference_id: currentUser.uid,
        metadata: {
          customer_name: customerName,
          ...(referredByCode && referredByCode.length > 0 ? { referred_by_code: referredByCode } : {}),
        },
        ...(referredByCode && referredByCode.length > 0 ? { promotion_code: 'promo_1IafJFGhmuGAYCWVYorVQ87A' } : {}),
      });
    }

    return docRef;
  }

  getDocumentReference(collection: string, document: string) {
    return doc(this.db, collection, document);
  }
}
