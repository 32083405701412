import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type PresenterModel from './presenter';

export default class BookingItineraryModel extends Model {
  // All attributes are demoralized values

  @attr('string') declare customerName?: string;

  @attr('string') declare customerAddress?: string;

  @attr('string') declare customerCity?: string;

  @attr('string') declare contactName?: string;

  @attr('timestamp') declare deliveryDate: Date;

  @belongsTo('presenter', { async: true, inverse: null }) declare presenter?: AsyncBelongsTo<PresenterModel>;

  @attr('array', { defaultValue: () => [] }) declare workshopTitles: string[];

  @attr('array', { defaultValue: () => [] }) declare workshopThumbnails: string[];

  @attr('array', { defaultValue: () => [] }) declare workshopFiles: string[];

  @attr('string') declare memo?: string;

  @attr('string') declare presenterNotes?: string;

  @attr('string') declare timetable?: string;

  @attr('string') declare yearGroups?: string;

  @attr('string') declare additionalNeeds?: string;

  @attr('string') declare topicKnowledge?: string;

  @attr('string') declare facilities?: string;

  @attr('string') declare technology?: string;

  @attr('string') declare carPark?: string;

  @attr('boolean', { defaultValue: false }) declare isCancelled: boolean;

  get date() {
    return this.deliveryDate;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'booking-itinerary': BookingItineraryModel;
  }
}
