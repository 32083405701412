import {
  getAuth,
  getIdTokenResult,
  getRedirectResult,
  onAuthStateChanged,
  signOut,
} from 'ember-cloud-firestore-adapter/firebase/auth';
// @ts-expect-error - it's okay
import FirebaseAuthenticator from 'ember-simple-auth/authenticators/base';

import type { AuthClaims } from 'ember-simple-auth/services/session';
import type { Auth, User, UserCredential, UserInfo } from 'firebase/auth';

interface AuthenticateCallback {
  (auth: Auth): Promise<UserCredential>;
}

interface CherryPickedUser {
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string;
  uid: string;
  emailVerified: boolean;
  isAnonymous: boolean;
  providerData: UserInfo[];
  refreshToken: string;
  tenantId: string | null;
}

interface AuthenticatedData {
  authUser: CherryPickedUser;
  claims?: AuthClaims;
}

function parseCherryPickedUser(user: User): CherryPickedUser {
  return {
    displayName: user.displayName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    photoURL: user.photoURL,
    providerId: user.providerId,
    uid: user.uid,
    emailVerified: user.emailVerified,
    isAnonymous: user.isAnonymous,
    providerData: user.providerData,
    refreshToken: user.refreshToken,
    tenantId: user.tenantId,
  };
}

function parseCherryPickedClaims(claims: AuthClaims): AuthClaims {
  return {
    accessControl: claims.accessControl,
    admin: claims.admin,
    email: claims.email,
    email_verified: claims.email_verified,
    name: claims.name,
    presenterId: claims.presenterId,
    subscription: claims.subscription,
    user_id: claims.user_id,
  };
}

const auth = getAuth();

export default class OneDayAuthenticator extends FirebaseAuthenticator {
  public async authenticate(callback: AuthenticateCallback): Promise<AuthenticatedData> {
    const { user } = await callback(auth);

    const token = await getIdTokenResult(user, true);

    return { authUser: parseCherryPickedUser(user), claims: parseCherryPickedClaims(token.claims) };
  }

  public invalidate(): Promise<void> {
    return signOut(auth);
  }

  public restore(): Promise<AuthenticatedData> {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        async (user) => {
          unsubscribe();

          if (user) {
            const freshToken = await getIdTokenResult(user, true);

            resolve({ authUser: parseCherryPickedUser(user), claims: parseCherryPickedClaims(freshToken.claims) });
          } else {
            // this is causing the double login issue
            getRedirectResult(auth)
              .then((credential) => {
                if (credential) {
                  resolve({ authUser: credential.user });
                } else {
                  reject();
                }
              })
              .catch(() => {
                reject();
              });
          }
        },
        () => {
          unsubscribe();
          reject();
        }
      );
    });
  }
}
