import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type UserModel from './user';
import type VideoModel from './video';

export default class ReviewModel extends Model {
  @attr('timestamp') declare createdAt: Date;

  @attr('boolean') declare enjoyment: boolean;

  @attr('boolean') declare learning: boolean;

  @attr('boolean') declare recommendation: boolean;

  @attr('number') declare playbackScore: number;

  @attr('number') declare contentScore: number;

  @attr('number') declare presenterScore: number;

  @attr('number') declare notesScore: number;

  @attr('number') declare totalScore: number;

  @attr('number') declare averageScore: number;

  @attr('string') declare comment: string;

  @belongsTo('video', { async: true, inverse: 'reviews' }) declare video: AsyncBelongsTo<VideoModel>;

  @belongsTo('user', { async: true, inverse: null }) declare user: AsyncBelongsTo<UserModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    review: ReviewModel;
  }
}
