import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import type CustomerModel from './customer';
import type PresenterModel from './presenter';

export default class UserModel extends Model {
  @attr('string') declare email: string;

  @attr('string') declare firstName: string;

  @attr('string') declare lastName: string;

  @attr('string') declare organisation: string;

  @attr('string') declare jobTitle: string;

  @attr('string') declare intercomId: string;

  @attr('array', { defaultValue: () => [] }) declare views: [];

  @attr('array', { defaultValue: () => [] }) declare reviews: [];

  @attr('array', { defaultValue: () => [] }) declare logins: [];

  @attr('number', { defaultValue: 0 }) declare viewCount: number;

  @attr('number', { defaultValue: 0 }) declare loginCount: number;

  @attr('timestamp') declare signedUp: Date;

  @attr('timestamp') declare lastSeen: Date;

  @attr('timestamp') declare trialEnd: Date;

  @attr('boolean', { defaultValue: false }) declare trialEnded: boolean;

  @attr('boolean', { defaultValue: false }) declare isSubscriber: boolean;

  @attr('string', { defaultValue: 'basic' }) declare subscription: string;

  @attr('boolean', { defaultValue: false }) declare isBillingAdmin: boolean;

  @attr('boolean', { defaultValue: false }) declare isAdmin: boolean;

  @attr({
    defaultValue() {
      return {
        accessCode: '',
        customerName: '',
        referralCode: '',
      };
    },
  })
  declare customerAttrs: Record<string, unknown>;

  @belongsTo('customer', {
    async: true,
    inverse: 'users',
    isRealtime: true,
  })
  declare customer: AsyncBelongsTo<CustomerModel>;

  @belongsTo('presenter', {
    async: true,
    inverse: 'user',
    isRealtime: true,
  })
  declare presenter: AsyncBelongsTo<PresenterModel>;

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  get searchIndex() {
    return `${this.firstName}${this.lastName}${this.email}${this.organisation}${
      this.customerAttrs['customerName'] || ''
    }`.toLowerCase();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
