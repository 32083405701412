import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

export default class IntercomService extends Service {
  @tracked booted = false;

  @tracked currentUser = null;

  appId = 'g4zkaby4';

  boot() {
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: this.appId,
      });
      this.booted = true;
      this.currentUser = null;
    }
  }

  shutdown() {
    if (window.Intercom) {
      window.Intercom('shutdown');
      window.Intercom('boot');
    }
  }

  update(userData) {
    if (window.Intercom) {
      window.Intercom('update', { app_id: this.appId, ...userData });

      if (userData && userData.email) {
        this.currentUser = userData.email;
      }
    }
  }
}
