import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import { getDownloadURL, getStorage, ref } from 'ember-cloud-firestore-adapter/firebase/storage';
import { trackedFunction } from 'ember-resources/util/function';

export default class ProfilePictureComponent extends Component {
  @service session;

  get filePath() {
    return this.session.data.authenticated.authUser.photoURL || null;
  }

  downloadUrl = trackedFunction(this, async () => {
    if (!this.filePath) {
      return;
    }

    const storage = getStorage();
    const imageRef = ref(storage, this.filePath);
    const url = await getDownloadURL(imageRef);

    return url;
  });
}
