/* eslint-disable array-callback-return */
/* eslint-disable func-names */
import { inject as service } from '@ember/service';
import EmberRouter from '@embroider/router';

import config from './config/environment';

export default class Router extends EmberRouter {
  @service intercom;

  location = config.locationType;

  rootURL = config.rootURL;

  constructor(owner, args) {
    super(owner, args);
    this.on('routeDidChange', (/* transition */) => {
      this.intercom.update();
    });
  }
}

Router.map(function () {
  this.route('auth');
  this.route('authenticated', { path: '' }, function () {
    this.route('video', { path: 'videos' }, function () {
      this.route('view', { path: '/:video_id' }, function () {
        this.route('feedback');
      });
    });
    this.route('admin', function () {
      this.route('users');
      this.route('customers');
      this.route('bookings', function () {
        this.route('edit', { path: '/:booking_id' });
      });
      this.route('workshops');
      this.route('presenters');
      this.route('availability');
    });

    this.route('user', function () {
      this.route('profile');
      this.route('bookings', function () {
        this.route('manage', { path: '/:booking_id' });
        this.route('feedback', { path: '/:booking_id/feedback' });
      });
    });
    this.route('calendar', function () {
      this.route('itineraries', function () {
        this.route('view', { path: '/:booking_id' });
      });
    });
    this.route('availability');
  });
  this.route('booking', function () {
    this.route('confirmation');
    this.route('manage', { path: '/:booking_id' });
  });
  this.route('faqs');
  this.route('index');
  this.route('login');
  this.route('not-found', { path: '/*path' });
  this.route('order', function () {
    this.route('success');
    this.route('cancel');
  });
  this.route('pricing');
  this.route('signup');
  this.route('schools');
  this.route('verify');
});
