import Model, { type AsyncHasMany, attr, hasMany } from '@ember-data/model';

import type VideoModel from './video';

export default class SubjectModel extends Model {
  @attr('string') declare title: string;

  @hasMany('video', {
    async: true,
    inverse: 'subjects',
    isRealtime: true,
  })
  declare videos: AsyncHasMany<VideoModel>;

  get heading1() {
    return this.title;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    subject: SubjectModel;
  }
}
